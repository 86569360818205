import React, { useState } from "react";
import triggerStyle from "./style.module.css";
import { ModalContainer, LoginSignUp } from "@trodly/login-signup";

const App = props => {
  const [active, activateModal] = useState(false);
  const onSuccess = userID => {
    console.log("login pop up user id ", userID);
    window.location.reload();
  };
  return (
    <>
      <div
        className={triggerStyle.container}
        onClick={() => activateModal(true)}
      />
      {active && (
        <ModalContainer
          mount="login-modal-root"
          close={() => activateModal(false)}
        >
          <LoginSignUp onSuccess={onSuccess} />
        </ModalContainer>
      )}
    </>
  );
};

export default App;
